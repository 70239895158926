export default [
	{
		path: '/m/login', //登录
		component: () => import('../views/m/login.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/m/home', //首页
		component: () => import('../views/m/home.vue')
	},
	{
		path: '/m/history', //学习记录
		component: () => import('../views/m/history.vue')
	},
	{
		path: '/m/video', //视频详情
		component: () => import('../views/m/video.vue')
	}
];
