function isDevelopment() {
	return process && process.env && process.env.NODE_ENV == 'development';
}
// 根据环境自动切换服务器地址
// const baseURL = isDevelopment() ? 'http://192.168.0.202:7003' : 'http://guyun.yun36524.cn/interface';
const baseURL = isDevelopment() ? 'http://guyun.yun36524.cn/interface' : 'https://guyun.yun36524.cn/interface';
const socketUrl = isDevelopment() ? 'ws://192.168.0.202:7003/jnsocket' : 'wss://guyun.yun36524.cn/jnsocket';
export default {
	baseURL,
	socketUrl
}
