import Vue from 'vue'
import VueRouter from 'vue-router'
import pc from './pc';
import m from './m';
import tools from '../utlis/tools.js';
Vue.use(VueRouter)
const router = new VueRouter({
	// mode: 'history',
	routes: [...pc,...m],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})
router.beforeEach((to, from, next) => {
	if (tools.IsPC()) {
		// pc端检测**
		// 如果不是pc链接
		if (to.path.indexOf("/pc/") == -1) {
			let newPath = to.fullPath.replace("/m/", "/pc/");
			next(newPath);
		} else {
			next() //放行
		}
	} else {
		// 移动端检测***
		// 如果不是移动链接
		if (to.path.indexOf("/m/") == -1) {
			// next() //放行
			let newPath = to.fullPath.replace("/pc/", "/m/");
			next(newPath);
		} else {
			next() //放行
		}
	}
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
// 	if (to.path === '/login' || to.path === '/register') {
// 		next();
// 	} else {
// 		let token = sessionStorage.getItem('Authorization'); //获取token
// 		if (token === null || token === '') {
// 			next('/login');
// 		} else {
// 			next() //放行
// 		}
// 	}
// });

export default router