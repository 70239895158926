import Vue from 'vue'
import http from './http'
import {Toast} from 'vant'

const {get, post, postFormData} = http;
import global_variable from './global_variable.js'
let baseUrl = global_variable.baseURL;

/* 公共 知识讲座 首页 */
export function zsjzHome(data) {
	return get(baseUrl + '/api/topVideo', data)
}

/* 公共 知识讲座 列表 */
export function zsjzList(data) {
	return get(baseUrl + '/api/publicList', data)
}

/* 公共 知识讲座 详情 */
export function zsjzInfo(data) {
	return get(baseUrl + '/api/publicInfo', data)
}

/* 公共 知识讲座 增加观看人数 */
export function zsjzAddCount(data) {
	return get(baseUrl + '/api/addCount', data)
}

/* 内部 技能提升 登录 */
export function jntsLogin(data) {
	return post(baseUrl + '/api/login', data)
}

/* 内部 技能提升 学习记录 */
export function jntsFindHistory(data) {
	return get(baseUrl + '/api/findHistory', data)
}

/* 内部 技能提升 首页 */
export function jntsHome(data) {
	return get(baseUrl + '/api/weiHowTopVideo', data)
}

/* 内部 技能提升 列表 */
export function jntsList(data) {
	return get(baseUrl + '/api/weiHouList', data)
}

/* 内部 技能提升 详情 */
export function jntsInfo(data) {
	return get(baseUrl + '/api/weiHowInfo', data)
}

/* 内部 技能提升 轮询观看时间 */
export function jntsAddHistory(data) {
	return get(baseUrl + '/api/addHistory', data)
}

export function downloadImg(data) {
	return get(baseUrl + '/api/downloadImg?id='+data)
}

export function findTime(data) {
	return get(baseUrl + '/api/findTime?id='+data)
}

export function findProgress() {
	return get(baseUrl + '/api/findProgress')
}
// 推荐列表
export function findRecommendList(data) {
	return get(baseUrl + '/api/findRecommendList',data)
}


Vue.prototype.$api = {
	zsjzHome,
	zsjzList,
	zsjzInfo,
	zsjzAddCount,
	jntsLogin,
	jntsFindHistory,
	jntsHome,
	jntsList,
	jntsInfo,
	jntsAddHistory,
	downloadImg,
	findTime,
	findProgress,
	findRecommendList
}