import Vue from 'vue'
import axios from 'axios'
import {
	Toast
} from 'vant'
import qs from 'qs'
import Router from '../router'


// 中配置的请求地址
import global_variable from './global_variable.js'
let baseUrl = global_variable.baseURL;


// 创建axios实例
const service = axios.create({
	// baseURL: baseUrl,
	timeout: 300000 // 请求超时
})

// 请求拦截器
service.interceptors.request.use(
	config => {
		let token = localStorage.getItem('webToken');
		if (token) {
			//设置token
			config.headers['token'] = token;
		}
		return config
	},
	error => {
		// 处理请求错误
		console.log(error) // for debug
		// return Promise.reject(error)
		return false
	}
)

// 响应拦截器
service.interceptors.response.use(
	response => {
		const res = response.data

		// 如果状态码不是200，则判断为错误.
		if (res.code != 200) {
			Toast.clear();
			Toast.fail(res.msg ? res.msg : '请求失败');
			// return Promise.reject(new Error(res.msg || 'Error'))
			return false
		} else {
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		if (error.response.status == 401 || error.response.data.code == 401) {
			Toast.fail('登录失效');
			Router.push('/');
		}
		return false
	}
)


// GET请求
function get(url, data) {
	return service.get(url, {
		params: data || {}
	});
}

// POST请求
function post(url, data) {
	return service.post(url, qs.stringify(data) || {});
}

// PostFormData请求
function postFormData(url, data) {
	let formData = new FormData();
	for (let k in data) {
		formData.append(k, data[k]);
	}
	return service.post(url, formData);
}


//封装http请求
const http = {
	get,
	post,
	postFormData,
	baseUrl: baseUrl
}

export default http



Vue.prototype.$http = http;