import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 全局变量
import globalVariable from '@/utlis/global_variable.js'
Vue.prototype.GLOBAL = globalVariable
// 工具类
import tools from '@/utlis/tools.js'
Vue.prototype.$tools = tools

import http from '@/utlis/http'
Vue.prototype.$http = http;
import '@/utlis/api';

new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	}
}).$mount('#app')

