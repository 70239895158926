<template>
	<div id="app">
		<el-backtop :bottom="30">
		  <div style="{
		    height: 100%;
		    width: 100%;
		    background-color: #f2f5f6;
		    box-shadow: 0 0 6px rgba(0,0,0, .12);
		    text-align: center;
		    line-height: 40px;
		    color: #1989fa;
		    }"><i class="el-icon-top"></i></div>
		</el-backtop>
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>
<script>
	export default {
		data() {
			return {}
		}
	}
</script>
<style lang="less">
	/*=========================Reset_start==========================*/
	*{
	    margin:0;
	    padding:0;
	}
	body,h1,h2,h3,h4,h5,h6,div,p,dl,dt,dd,ol,ul,li,form,table,th,td,a,img,span,strong,var,em,input,textarea,select,option{margin: 0; padding: 0;}
	html,body{font-family:"微软雅黑","Helvetica Neue",Helvetica,Arial,"Microsoft YaheiUI","Micrisoft YaHei",SimHei,"\5B88\4F53",simsun,sans-serif; font-size: 14px; text-align: left;}
	ul,ol{list-style: none;}
	img{border: 0;}
	input,select,textarea{outline:0;}
	textarea{resize:none; overflow: auto;}
	table{border-collapse: collapse; border-spacing: 0;}
	th,strong,var,em{font-weight: normal; font-style: normal;}
	a{text-decoration: none;cursor: pointer;color: #333;}
	a:link,a:visited,a:hover,a:active{text-decoration:none;}
	/*==========================Reset_End===========================*/
</style>
