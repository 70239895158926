export default [
	{
		path: '/',
		redirect: '/pc/login' //重定向
	},
	{
		path: '/pc/login', //登录
		component: () => import('../views/pc/login.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/pc/home', //首页
		component: () => import('../views/pc/home.vue')
	},
	{
		path: '/pc/history', //学习记录
		component: () => import('../views/pc/history.vue')
	},
	{
		path: '/pc/video', //视频详情
		component: () => import('../views/pc/video.vue')
	}
];
